<template>
  <tr>
    <td>
      <strong>{{ inspection.id }}</strong>
    </td>
    <td class="nowrap-column">
      Компьютерная диагностика:
      <strong class="font-medium" v-if="inspection.computer_diagnostic_count">есть ошибки</strong>
      <strong class="font-medium" v-else>нет ошибок</strong>
    </td>
    <td class="nowrap-column">
      <span v-if="inspection.creator">
        {{ inspection.creator.name }} (ID {{ inspection.creator.id }})
      </span>
      <span v-else>
        Создан автоматически
      </span>
    </td>
    <td class="nowrap-column">
      {{ inspection.created_date }}
    </td>
    <td class="nowrap-column">
      <a :href="reportSourcePath + inspection.car_id + inspection.car_id + '_' + inspection.id + '_report.jpg'"
         target="_blank"
         class="btn btn-success"
         style="margin: 3px 2px 2px 3px"
         title="Открыть"
         type="button">
        <i class="fas fa-file"></i>
      </a>
    </td>
    <td class="nowrap-column">
      <template v-if="inspection.creator">
        <router-link v-if="([1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
          inspection.creator.id === $store.getters['auth/authUser'].id ||
          inspection.car.creator.id === $store.getters['auth/authUser'].id)"
                     :to="{ name: 'car.inspection.edit', params: {
                       carInspectionId: inspection.id,
                       carId: inspection.car_id,
                       inspection: inspection,
                     }}"
                     class="btn btn-primary"
                     style="margin: 3px 2px 2px 3px"
                     title="Редактировать"
                     type="button">
          <i class="fa fa-edit"></i>
        </router-link>
      </template>
    </td>
    <td class="nowrap-column">
      <button class="btn btn-danger" style="margin: 3px 2px 2px 3px"
              title="Удалить" type="button"
              v-on:click="removeInspection">
        <i class="fa fa-trash"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";
import VuejsDialog from 'vuejs-dialog';

Vue.use(VuejsDialog);

export default {
  name: 'car-inspection-item',

  props: {
    inspection: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  data: () => ({
    reportSourcePath: process.env.VUE_APP_API_URL + '/storage/cars/inspections/',
  }),

  methods: {
    removeInspection() {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/car/' + this.inspection.car_id + '/inspections', {
          data: {
            inspection_id: this.inspection.id,
          }
        }).then(() => {
          this.EventBus.$emit('table-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
